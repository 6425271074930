import Image, { ImageProps } from 'next/image';
import { FunctionComponent } from 'react';
import { convertImageType } from './convertImageType';

/**
 * Takes in an image string and returns an image source string converted into the file type indicated
 * @category Helper
 * @func
 * @param {string} imgSrc - The image's source
 * @param {string} imgType - The image's mime type
 * @returns {string}
 * @example
 *
 * <ResponsiveImage
    imgPath={imgPath}
    img={{ mobile: img.mobile, tablet: img.tablet, desktop: img.desktop }}
    className={styles.img}
    alt={img.altText}
    fill
    loading='eager'
  />
 * );
 * */

interface ResponsiveImageProps extends Omit<ImageProps, 'src'> {
  imgPath: string;
  img: {
    mobile: string;
    tablet: string;
    desktop: string;
  };
  alt: string;
  className?: string;
  width?: number;
  height?: number;
  loading?: 'eager' | 'lazy' | undefined;
  useNextImg?: boolean;
  addlImgProps?: ImageProps;
  fill?: boolean;
}

export const ResponsiveImage: FunctionComponent<ResponsiveImageProps> = ({
  imgPath,
  img,
  className,
  alt,
  width,
  height,
  loading,
  addlImgProps,
  fill = false,
  useNextImg = true,
}): JSX.Element => {
  const { mobile, tablet, desktop } = img;
  const matchResult = desktop.match(/\.(\w+$)/);
  const imgType = matchResult ? `image/${matchResult[1]}` : '';
  const isSVG = desktop.match(/\.\w+$/)?.includes('.svg');
  const dimensions = fill ? { fill } : { width, height };

  const image = useNextImg ? (
    <Image
      {...addlImgProps}
      {...dimensions}
      src={imgPath + mobile}
      className={className}
      alt={alt}
      loading={loading}
    />
  ) : (
    // eslint-disable-next-line @next/next/no-img-element
    <img
      {...addlImgProps}
      src={imgPath + mobile}
      className={className}
      alt={alt}
      loading={loading}
    />
  );
  return (
    <picture>
      {isSVG ? (
        <>
          <source
            srcSet={imgPath + desktop}
            type='image/svg'
            media='(min-width: 1440px)'
          />
          <source
            srcSet={imgPath + tablet}
            type='image/svg'
            media='(min-width: 768px)'
          />
          {image}
        </>
      ) : (
        <>
          <source
            srcSet={imgPath + convertImageType(desktop, '.avif')}
            type='image/avif'
            media='(min-width: 1440px)'
          />
          <source
            srcSet={imgPath + convertImageType(desktop, '.webp')}
            type='image/webp'
            media='(min-width: 1440px)'
          />
          <source
            srcSet={imgPath + desktop}
            type={imgType}
            media='(min-width: 1440px)'
          />
          <source
            srcSet={imgPath + convertImageType(tablet, '.avif')}
            type='image/avif'
            media='(min-width: 768px)'
          />
          <source
            srcSet={imgPath + convertImageType(tablet, '.webp')}
            type='image/webp'
            media='(min-width: 768px)'
          />
          <source
            srcSet={imgPath + tablet}
            type={imgType}
            media='(min-width: 768px)'
          />
          <source
            srcSet={imgPath + convertImageType(mobile, '.avif')}
            type='image/avif'
          />
          <source
            srcSet={imgPath + convertImageType(mobile, '.webp')}
            type='image/webp'
          />
          {image}
        </>
      )}
    </picture>
  );
};
export default ResponsiveImage;
