import { SVGAttributes } from 'react';

type BeakProps = SVGAttributes<SVGElement>;

const Beak = (props: BeakProps) => {
  return (
    <svg
      width='36'
      height='10'
      viewBox='0 0 36 10'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M16.5858 6.58579L10.8787 0.87868C10.3161 0.316071 9.55301 0 8.75736 0H27.2426C26.447 0 25.6839 0.31607 25.1213 0.878679L19.4142 6.58579C18.6332 7.36684 17.3668 7.36684 16.5858 6.58579Z'
        fill='white'
      />
      <path
        d='M8 0.5H10L18 8.5L26 0.5H28'
        stroke='#898C93'
        strokeLinecap='round'
      />
      <path
        d='M8 0.5H10L18 8.5L26 0.5H28'
        stroke='black'
        strokeOpacity='0.2'
        strokeLinecap='round'
      />
    </svg>
  );
};

export default Beak;
