/**
 * Takes in an image string and returns an image source string converted into the file type indicated
 * @category Helper
 * @func
 * @param {string} imgSrc - The image's source
 * @param {string} imgType - The image's mime type
 * @returns {string}
 * @example
 * // Returns `/images/sections/features/deals/deals.avif`
 * convertImageType('/images/sections/features/deals/deals.jpeg', '.avif');
 * */
export const convertImageType = (imgSrc: string, imgType: string): string => imgSrc.replace(/\.\w+$/, imgType);

export default convertImageType;
