import useGCLID from './gclid';

/**
 * ElementClickedFunction
 * @category Segment
 * @func
 */
export type ElementClickedFunction = (
  /**
   * A valid HTML element. If used in event listeners, then typically the value will be `e.currentTarget`
   * @param
   */
  element: HTMLElement,
) => void;

/**
 * Tracks an ElementClicked event
 * Returns a function that to be passed to an element's onClick handler
 *
 * @func
 * @category Segment
 * @param {string} elementName - The element's name, such as **CTA**
 * @param {string} elementPosition - The element's position, such as **NAVBAR**
 * @param {string} elementType - Defaults to "LINK"
 * @param {string} elementText - Defaults to the passed in element's innerText
 * @return {ElementClickedFunction}
 */
export default function useElementClicked(
  elementName: string,
  elementPosition: string,
  elementType?: string,
  elementText?: string,
): ElementClickedFunction {
  const gclid = useGCLID();

  return (element) => {
    analytics.track('Element Clicked', {
      elementPosition,
      elementName,
      gclid,
      elementText: elementText ?? element.innerText,
      elementType: elementType ?? 'LINK',
      url: window.location.href,
      path: window.location.pathname,
    });
  };
}
